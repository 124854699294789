@charset "UTF-8";
/*
*  html5 doctor css reset | http://html5doctor.com/html-5-reset-stylesheet
*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle;
  font: inherit; }

textarea {
  font: inherit; }

ol, ul, dt, dl {
  list-style: none; }

blockquote, q {
  quotes: none; }

fieldset, img {
  border: none; }

a, a:link a:active a:visited {
  color: #000000;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: normal; }

input, textarea, select {
  outline: none;
  /*tira a borda dourada do chrome*/ }

textarea {
  resize: none;
  /*não deixa redimensionar o textarea no chrome*/ }

a img {
  border: 0px;
  margin: 0px; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  color: inherit; }

/**
 * TOOLS
 **/
* html > body .clearfix {
  display: inline-block;
  width: 100%; }

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.clear {
  clear: both;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1px; }

.replace, .wt {
  background-color: transparent;
  text-indent: -50000px;
  overflow: hidden;
  display: block; }

.replace-bt {
  background-repeat: no-repeat;
  background-position: left top;
  background-color: transparent;
  text-indent: -50000px;
  line-height: 999999px;
  cursor: pointer;
  overflow: hidden;
  display: block;
  border: none; }

.replace-txt {
  text-indent: -999999px; }

body {
  padding-top: 80px; }

.header {
  position: fixed;
  background: #fff;
  height: 80px;
  width: 100%;
  top: 0;
  left: 0;
  padding: 15px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 20000; }

.logo {
  float: left;
  width: 225px; }

.menu {
  float: right;
  width: 594px;
  margin-right: 14px; }
  .menu ul {
    float: right; }
    .menu ul li {
      float: left; }
      .menu ul li a {
        float: left;
        padding: 16px 8px;
        font-size: 14px;
        color: #616161;
        text-transform: uppercase;
        letter-spacing: -0.4px;
        transition: all 0.5s ease;
        border-bottom: 2px solid transparent; }
        .menu ul li a:hover {
          text-decoration: none;
          color: #000;
          border-color: #ddd; }
        .menu ul li a.active {
          text-decoration: none;
          color: #000;
          border-color: #ddd; }

.language {
  float: right;
  width: 44px; }
  .language ul {
    width: 100%; }
    .language ul li {
      float: left;
      margin: 13px 1px; }
      .language ul li a {
        float: left;
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid #d4d4d4;
        border-radius: 50%;
        overflow: hidden;
        font-size: 9px;
        text-align: center;
        line-height: 18px;
        color: #616161;
        text-transform: uppercase;
        transition: all 0.1s linear; }
        .language ul li a:hover {
          background: #616161;
          color: #fff;
          text-decoration: none; }
        .language ul li a.active {
          background: #616161;
          color: #fff;
          text-decoration: none; }

/** Estilos do Footer **/
html, body {
  height: 100%; }

body {
  min-height: 100%;
  font-size: 14px;
  font-family: "roihuregular";
  color: #616161;
  background: #fcfcfc; }

/*img, video, embed, iframe, code{
	max-width: 100%
}*/
hr {
  width: 100%;
  display: block;
  float: left;
  border: none;
  background: transparent;
  height: 0;
  border-bottom: 1px solid red;
  border-top: 1px solid #ccc; }

* {
  box-sizing: border-box; }

*:after {
  box-sizing: border-box; }

*:before {
  box-sizing: border-box; }

.row, .slides, .the-group, .gallery-group, .our-brands, .contact, .footer, .internal-header, .bg-top-history, .bg-top-mission, .bg-top-structure, .bg-top-sustainable, .content-internal, .timeline, .frame-internal, .frame-internal-full, .description-mission, .centered-title, .centered-title-sustainable, .frame-internal-full h3, .centered-subitile, .frame-internal-full h4, .frame-internal-full h5, .menu-internal {
  display: block;
  float: left;
  width: 100%; }

.content {
  display: block;
  width: 980px;
  margin: 0 auto; }

.hide {
  position: absolute;
  height: 0;
  width: 0;
  color: transparent;
  text-indent: -9000px;
  top: 0;
  left: 0;
  z-index: -40; }

.parallax-slider {
  position: fixed;
  top: 80px;
  width: 100%;
  height: 600px; }

.the-group {
  margin-top: 600px; }

.slides {
  position: relative; }
  .slides a {
    position: relative; }
    .slides a img {
      float: left;
      width: 100%;
      opacity: 0;
      transition: all 2.5s ease; }

.opacity-100 {
  opacity: 1 !important; }

.the-group {
  background: #f1f1f1;
  padding: 96px 0;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 100; }

.title-the-group {
  color: #000;
  font-size: 30px;
  font-family: "roihublack_italic";
  text-transform: uppercase; }

.text-the-group {
  line-height: 33px;
  margin-top: 30px;
  margin-left: 60px; }

.gallery-group {
  margin-top: 40px;
  text-align: center;
  font-size: 0; }

.lt-ie9 input[type="text"] {
  line-height: 29px; }

.lt-ie9 .item-gallery:hover .name-item {
  display: none; }

.lt-ie9 .content-timeline {
  border: 1px solid #E7E6E7; }

.lt-ie9 .header {
  border-bottom: 1px solid #E7E6E7; }

.lt-ie10 .breakpoint:before {
  box-shadow: none; }

.item-gallery {
  width: 489px;
  height: 292px;
  display: inline-block;
  background: #ccc;
  font-size: 14px;
  position: relative;
  overflow: hidden; }
  .item-gallery:after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    position: absolute;
    transition: all 0.8s ease; }
  .item-gallery:hover {
    text-decoration: none; }
  .item-gallery .name-item {
    position: relative;
    display: inline-block;
    margin-top: 120px;
    border: 1px solid #fff;
    padding: 0 40px;
    color: #fff;
    font-size: 24px;
    font-family: "roihublack";
    line-height: 50px;
    background: rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease; }
    .item-gallery .name-item:hover {
      text-decoration: none; }
  .item-gallery:hover .text-item-gallery {
    top: 50%;
    opacity: 1; }
  .item-gallery:hover .name-item {
    transform: translateY(-200px);
    opacity: 0; }
  .item-gallery:hover:after {
    opacity: 0.5; }

.text-item-gallery {
  color: #fff;
  position: absolute;
  height: 150px;
  width: 240px;
  top: 150%;
  left: 50%;
  margin-left: -120px;
  margin-top: -75px;
  transition: top 0.5s ease;
  opacity: 0; }
  .text-item-gallery small {
    font-size: 60px;
    font-weight: lighter;
    font-family: "roihulight";
    clear: both; }
  .text-item-gallery p {
    width: 94%;
    margin: 0 auto;
    display: block;
    font-size: 21px;
    line-height: 40px;
    font-family: "roihulight"; }

.our-brands {
  background: #fff;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1001;
  padding: 90px 0 60px; }

.title-brand {
  color: #000;
  font-size: 30px;
  font-family: "roihublack_italic";
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  padding: 0 0 40px 0; }

.brand-item {
  float: left;
  width: 50%;
  text-align: center; }
  .brand-item img {
    display: inline-block;
    max-width: 100%;
    transition: all 0.5s ease; }
  .brand-item:hover img {
    transform: scale(1.04); }

.contact {
  background: #000;
  padding: 90px 0 150px;
  position: relative;
  z-index: 4000; }

.title-contact {
  color: #fff;
  font-size: 30px;
  font-family: "roihublack_italic";
  text-transform: uppercase; }

.form-contact {
  display: block;
  margin: 60px auto;
  width: 846px; }
  .form-contact ::placeholder {
    line-height: 28px;
    color: #fff;
    opacity: 1; }

:-ms-input-placeholder {
  color: red; }

.input-base, .input-small, .input-medium, .input-big, .textarea-big {
  border: 1px solid #808080;
  background: transparent;
  color: #fff;
  height: 50px;
  padding: 10px;
  float: left;
  display: block;
  margin: 5px 25px; }

.input-small {
  width: 231px; }

.input-medium {
  width: 372px; }

.input-big {
  width: 793px; }

.textarea-big {
  width: 793px;
  height: 150px; }

.error {
  border-color: #ff3f3f; }

.error-text {
  margin-top: 20px;
  color: #ff3f3f;
  font-size: 12px;
  padding: 10px 20px;
  background: #fff;
  border-bottom: 2px solid #ff3f3f;
  float: left; }
  .error-text p {
    display: block;
    float: left;
    clear: both; }

.label-form {
  font-family: "roihulight_italic";
  color: #fff;
  font-size: 11px;
  line-height: 28px;
  float: left;
  margin-top: 10px;
  margin-left: 25px; }

.submit-form-contact {
  float: right;
  height: 50px;
  width: 230px;
  border: 1px solid #808080;
  padding: 10px;
  background: #fff;
  margin-right: 25px;
  margin-top: 10px;
  cursor: pointer;
  position: relative; }
  .submit-form-contact input[type="submit"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: #000;
    font-family: "roihublack";
    text-align: left;
    font-size: 18px;
    border: none;
    text-indent: 20px;
    cursor: inherit; }
  .submit-form-contact:before {
    position: absolute;
    content: "";
    right: 20px;
    top: 20px;
    height: 10px;
    width: 40px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAALCAYAAAFt42+qAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEZCMkYzMUU4NkIwMTFFNDk0OEJGRkM4NjNGQTZEMDYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEZCMkYzMUY4NkIwMTFFNDk0OEJGRkM4NjNGQTZEMDYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4RkIyRjMxQzg2QjAxMUU0OTQ4QkZGQzg2M0ZBNkQwNiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4RkIyRjMxRDg2QjAxMUU0OTQ4QkZGQzg2M0ZBNkQwNiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgUx2goAAADFSURBVHjaYmBABcIMWMAbEAEQQIQRQAAxAnE5DjlWIG4GYm6AAKLIlA4g3oxP82IQARBA5KCPQDwX3RmhJBoC8uRSKHsrGfrBQApdACCASEWZDFQAoHTwF4glkQVZ8EQNLvAbiJ8B8T8gjgDi1SBD7pFhCDuU/Y8c7/AB8X8glkH3DikgCposUABAgNEagWLzIRBbMgwCUAENRhD+DMRdQKyILQP/H2CHvgbiDHpYlIsUIt+AeCIQq9Lbt4lA/BKIPQkpBABcFCAA36W4UQAAAABJRU5ErkJggg==");
    background-repeat: no-repeat;
    transition: right 0.3s ease; }
  .submit-form-contact:hover:before {
    right: 10px; }
  .submit-form-contact:active:before {
    right: -100px; }

.footer {
  background: #f1f1f1;
  padding: 28px 0 35px;
  position: relative;
  z-index: 10000; }

.address {
  float: left;
  width: 850px;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  text-align: center; }

.wt {
  float: right;
  width: 25px;
  height: 21px;
  transition: opacity 0.4s ease;
  background-image: url("../../img/site/common/misc/icon-wt.png");
  background-repeat: no-repeat; }
  .wt:hover {
    opacity: 0.8; }

.footer-logo {
  float: left;
  width: 100px; }

.internal-header, .bg-top-history, .bg-top-mission, .bg-top-structure, .bg-top-sustainable {
  height: 67px;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #f1f1f1; }

.bg-top-history {
  background-image: url("../../img/site/common/bg/topo_historia.jpg"); }

.bg-top-mission {
  background-image: url("../../img/site/common/bg/topo_missao.jpg"); }

.bg-top-structure {
  background-image: url("../../img/site/common/bg/topo_estrutura.jpg"); }

.bg-top-sustainable {
  background-image: url("../../img/site/common/bg/topo_sustentabilidade.jpg"); }

.content-internal {
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2);
  background: #f1f1f1;
  padding: 86px 0 10px 0;
  position: relative;
  z-index: 2; }

.title-bread-crumb {
  float: left;
  font-size: 30px;
  color: #000;
  font-family: "roihuitalic";
  text-transform: uppercase; }
  .title-bread-crumb strong {
    font-family: "roihublack_italic"; }

.back {
  float: right;
  text-transform: uppercase;
  color: #616161;
  font-family: "roihuitalic";
  margin-top: 15px; }

.box-article {
  float: left;
  margin: 19px 0 40px 72px;
  line-height: 32px; }
  .box-article img {
    max-width: 100%; }

.timeline {
  margin: 40px 0; }

.breakpoint {
  float: left;
  width: 188px;
  height: auto;
  position: relative; }
  .breakpoint:before {
    content: "";
    position: absolute;
    width: 1px;
    background: #fff;
    height: 100%;
    right: 57px;
    top: 40px;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.05);
    z-index: 1;
    border-bottom: 80px solid #f1f1f1; }
  .breakpoint li {
    float: left;
    width: 100%;
    position: relative;
    z-index: 2; }
    .breakpoint li:first-child a {
      margin-top: 0; }
      .breakpoint li:first-child a .arrow {
        top: 25px; }
    .breakpoint li.active .circle-big {
      background: #d1d1d1;
      color: #fff; }
    .breakpoint li.active .circle-small {
      background-color: #d1d1d1; }
    .breakpoint li.active .arrow {
      display: block; }
    .breakpoint li:hover .circle-big {
      background: #d1d1d1;
      color: #fff; }
    .breakpoint li:hover .circle-small {
      background-color: #d1d1d1; }
    .breakpoint li a {
      float: left;
      width: 100%;
      margin: 30px 30px 0 0; }

.circle-big {
  float: left;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  border: solid 5px #fff;
  text-align: center;
  font-size: 24px;
  line-height: 70px;
  color: #d1d1d1;
  font-family: "roihuregular";
  font-weight: bold;
  transition: all 0.2s ease; }

.circle-small {
  float: left;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  border: solid 2px #fff;
  margin-left: 40px;
  margin-top: 30px; }

.wrap-timeline {
  float: right;
  width: 718px;
  padding: 30px;
  background: #fff;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.1); }

.content-timeline {
  float: right;
  width: 100%;
  background: #fff; }
  .content-timeline img {
    max-width: 100%;
    float: left;
    margin-bottom: 30px; }

.arrow {
  display: none;
  position: absolute;
  right: -1px;
  top: 55px;
  border-top: 15px solid transparent;
  border-right: 15px solid #E1E1E1;
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent; }
  .arrow:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: -15px;
    left: -14px;
    border-top: 15px solid transparent;
    border-right: 15px solid #fff;
    border-bottom: 15px solid transparent;
    border-left: 15px solid transparent; }

.content-timeline {
  display: none; }

.frame-internal, .frame-internal-full {
  background-color: #fff;
  padding: 50px 50px 50px 70px;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.1);
  margin: 30px 0 0; }

.title-mission {
  color: #000;
  font-size: 30px;
  font-family: "roihublack_italic";
  text-transform: uppercase; }

.description-mission {
  margin-top: 30px;
  line-height: 26px; }

.frame-internal-full {
  padding: 30px; }
  .frame-internal-full img {
    width: 100%; }
  .frame-internal-full p {
    display: inline-block; }

.centered-title, .centered-title-sustainable, .frame-internal-full h3 {
  text-align: center;
  color: #000;
  font-size: 24px;
  font-family: "roihuitalic";
  text-transform: uppercase;
  font-weight: bold;
  padding: 40px 0; }

.centered-title-sustainable, .frame-internal-full h3 {
  padding: 0; }

.centered-subitile, .frame-internal-full h4, .frame-internal-full h5 {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-family: "roihuitalic";
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 30px; }

.frame-internal-full img {
  float: left; }

.menu-internal {
  background: #f1f1f1;
  position: relative;
  z-index: 3200;
  margin-top: -10px;
  padding: 0 0 40px 0;
  text-align: center;
  font-size: 0; }

.item-menu {
  display: inline-block;
  width: 326px;
  text-align: center;
  height: 135px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease; }
  .item-menu span {
    transition: all 0.5s ease;
    display: inline-block;
    font-size: 18px;
    font-family: "roihublack";
    color: #fff;
    text-align: center;
    line-height: 30px;
    height: 33px;
    padding: 0 30px;
    background-color: rgba(0, 0, 0, 0.2);
    border: solid 1px #fcfcfc;
    margin-top: 56px; }
  .item-menu:hover {
    opacity: 0.8; }
